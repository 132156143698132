import { ControlErrors } from 'client/js/util/form_utils';
import PropTypes from "prop-types";

const ControlledErrors = (props, context) => {
  if(!context.serverErrors)
    return null;

  let errors = [];

  let names = props.names;

  if(props.all)
    names = Object.keys(context.serverErrors);

  names.map((item) => {
    let item_errors = context.serverErrors[item];
    if(context.fieldsValidated && context.fieldsValidated.includes(item) && item_errors && item_errors.length)
      errors = errors.concat(item_errors);
  })

  return <ControlErrors errors={errors} />;
}

ControlledErrors.propTypes = {
  names: PropTypes.array.isRequired
};

ControlledErrors.contextTypes = {
  fieldsValidated: PropTypes.array,
  serverErrors: PropTypes.object
};

class ControlledSelect extends React.Component {
  build_name() {
    let classes = ["form-control"];
    let errors = this.context.serverErrors && this.context.serverErrors[this.props.name];

    if(this.context.fieldsValidated && this.context.fieldsValidated.includes(this.props.name))
      classes.push(errors && errors.length ? 'is-invalid' : 'is-valid')

    if(this.props.required)
      classes.push("required");

    if(this.props.extraClass)
      classes.push(this.props.extraClass);

    classes.push(this.props.type);

    return classes.join(' ');
  }

  render() {
    let value = this.props.value || "";
    if(this.context.formData && this.context.formData.hasOwnProperty(this.props.name))
      value = this.context.formData[this.props.name];

    return <select value={value} onChange={(e) => this.context.formDispatch('value_changed', e, {name: this.props.name, value: e.target.value})}
              aria-required={this.props.required} required={this.props.required} id={`${this.context.namespace}_${this.props.name}`}
              className={this.build_name()}
              name={`${this.context.namespace}[${this.props.name}]`} type={this.props.type}>
            {this.props.children}
          </select>
  }
}

ControlledSelect.propTypes = {
  name: PropTypes.string.isRequired
};

ControlledSelect.contextTypes = {
  namespace: PropTypes.string.isRequired,
  fieldsValidated: PropTypes.array,
  serverErrors: PropTypes.object,
  formDispatch: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired
};

class ControlledInput extends React.Component {
  constructor(props) {
    super(props);
  }

  build_name() {
    let classes = ["form-control"];
    let errors = this.context.serverErrors && this.context.serverErrors[this.props.name];
    const has_errors = this.props.hasError || (errors && errors.length);
    const was_validated = this.context.fieldsValidated && this.context.fieldsValidated.includes(this.props.name);

    if(was_validated || this.props.hasError)
      classes.push(has_errors ? 'is-invalid' : 'is-valid')

    if(this.props.required)
      classes.push("required");

    if(this.props.extraClass)
      classes.push(this.props.extraClass);

    classes.push(this.props.type);

    return classes.join(' ');
  }

  render() {
    let value = "";
    if(this.context.formData && this.context.formData[this.props.name])
      value = this.context.formData[this.props.name];

    let changeHandler = this.props.onChange ? this.props.onChange : (e) => {this.context.formDispatch('value_changed', e, {name: this.props.name, value: e.target.value})};
    let otherProps = {};
    if(this.props.type == 'checkbox') {
      otherProps['checked'] = value == '1'; // set checked
      value = this.props.value;
    }

    return <input value={value} onChange={changeHandler}
          aria-required={this.props.required}
          required={this.props.required} id={this.props.id ? this.props.id : `${this.context.namespace}_${this.props.name}`}
          disabled={this.props.disabled}
          className={this.build_name()}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
          min={this.props.min} max={this.props.max} step={this.props.step}
          maxLength={this.props.maxLength}
          autoFocus={this.props.autoFocus}
          readOnly={this.props.readOnly}
          name={`${this.context.namespace}[${this.props.name}]`}
          type={this.props.type} {...otherProps} />
  }
}

ControlledInput.propTypes = {
  name: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
  required: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};

ControlledInput.defaultProps = {
  required: false,
  disabled: false
};

ControlledInput.contextTypes = {
  namespace: PropTypes.string.isRequired,
  fieldsValidated: PropTypes.array,
  serverErrors: PropTypes.object,
  formData: PropTypes.object,
  formDispatch: PropTypes.func.isRequired
};


class ControlledCollection extends React.Component {
  constructor(props) {
    super(props);
  }

  build_name() {
    let classes = ["form-control"];
    let errors = this.context.serverErrors && this.context.serverErrors[this.props.name];

    if(this.context.fieldsValidated && this.context.fieldsValidated.includes(this.props.name))
      classes.push(errors && errors.length ? 'is-invalid' : 'is-valid')

    if(this.props.required)
      classes.push("required");

    if(this.props.extraClass)
      classes.push(this.props.extraClass);

    classes.push(this.props.type);

    return classes.join(' ');
  }

  render() {
    let value = [];
    if(this.context.formData && this.context.formData.hasOwnProperty(this.props.name))
      value = this.context.formData[this.props.name];

    let changeHandler = this.props.onChange ? this.props.onChange : (e) => this.context.formDispatch("boolean_collection_check", e, {field_name: this.props.name});
    let otherProps = {};
    if(this.props.type == 'checkbox' && value.includes(this.props.value))
      otherProps['checked'] = true;

    return <input value={this.props.value} onChange={changeHandler}
          aria-required={this.props.required} required={this.props.required} id={this.props.id ? this.props.id : `${this.context.namespace}_${this.props.name}`}
          className={this.build_name()}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
          name={`${this.context.namespace}[${this.props.name}]`}
          type={this.props.type} {...otherProps} />
  }
}

ControlledCollection.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
  onChange: PropTypes.func
};

ControlledCollection.defaultProps = {
  type: "checkbox"
}

ControlledCollection.contextTypes = {
  namespace: PropTypes.string.isRequired,
  fieldsValidated: PropTypes.array,
  serverErrors: PropTypes.object,
  formData: PropTypes.object,
  formDispatch: PropTypes.func.isRequired
};

const FormErrors = () => {
  return <div className='row form-group'>
      <div className="col-md-9 offset-md-3">
        <ControlledErrors all />
      </div>
    </div>
}

export { ControlledCollection, ControlledErrors, ControlledInput, ControlledSelect, FormErrors };

