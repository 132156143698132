import React from "react";

interface CalloutProps {
  title?: React.ReactNode;
  text?: React.ReactNode;
  closeCallback?: () => void;
  calloutClass?: "info" | "success" | "warning" | "danger";
  children?: React.ReactNode;
}

const Callout: React.FC<CalloutProps> = (props) => {
  let name = "callout";
  if(props.calloutClass)
    name = [name, `callout-${props.calloutClass}`].join(' ');

  const close = props.closeCallback && <a href='#' className="close" onClick={props.closeCallback}>×</a>;

  return (
    <div className={name}>
      {close}{!!props.title && props.title !== '' && <h4>{props.title}</h4>}

      {props.children ? props.children : <p>{props.text}</p>}
    </div>
  );
}

export default Callout;
