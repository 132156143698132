import { useQuery } from '@tanstack/react-query';
import Callout from 'client/js/util/callout';
import { ServerErrorCallout } from 'client/js/util/form_utils';
import InlineSpinnner from 'client/js/util/inline_spinner';
import { PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { toast } from 'react-toastify';

const parseXHRError = (response) => {
  let { status } = response;
  let extendedStatus = null;
  let title = "Fehler";
  let message = response.toString();
  let validationErrors = null;
  let isValidationError = false;
  let validationMessage = null;

  if (typeof response === 'string') {
    message = response;

    return { status: null, extendedStatus: null, title, message };
  }

  try {
    const jsonResponse = JSON.parse(response.responseText);

    if (jsonResponse.status_code) {
      status = jsonResponse.status_code;
      extendedStatus = jsonResponse.status_code;
    }

    if(status == 400 && jsonResponse.errors) {
      validationErrors = jsonResponse.errors;
      isValidationError = true;

      validationMessage = Object.keys(jsonResponse.errors).map((key) => {
        if(key == 'base')
          return jsonResponse.errors[key].join(', ');

        return `"${key}": ${jsonResponse.errors[key].join(', ')}`;
      }).join(', ');
    } else if (jsonResponse.code || jsonResponse.status) {
      // we can remove .status when the backend is changed to .code in all cases
      extendedStatus = jsonResponse.code || jsonResponse.status;
      message = jsonResponse.message || message;
    } else if (jsonResponse.message) {
      message = jsonResponse.message;
    }
  } catch (e) {
    // Ignore JSON parsing errors
  }

  switch (status) {
    case 404:
      title = "404 - Nicht gefunden";
      message = "Die angeforderte Resource wurde nicht gefunden.";
      break;
    case 401:
      title = "401 - Unauthorized";
      message = "Deine Sitzung ist abgelaufen oder Du bist nicht mehr eingeloggt. Bitte logge Dich ein!";
      break;
    case 402:
      title = "402 - Webhosting-Paket erforderlich";
      message = "Für diese Funktion ist ein Webhosting-Paket notwendig, in Deinem jetzigen Vertrag ist diese Funktion nicht enthalten. Bitte starte einen 14-tägigen kostenlosen Test oder buche ein Webhosting-Paket.";
      break;
    case 'shared_hosting_required':
      title = "Webhosting-Paket nötig";
      message = 'Auf Deinem Account ist derzeit kein Webhosting-Paket vorhanden. Bitte füge ein Webhosting-Paket unter "Webhosting-Paket" hinzu.';
      break;
    case 500:
      title = `Serverfehler ${status}`;
      message = "Es gab ein Serverproblem. Bitte versuche es später erneut.";
      break;
    case 'email_unconfirmed':
      title = "Account-Aktivierung erforderlich";
      message = "Für diese Funktion ist die Aktivierung Deines Accounts notwendig. Bitte klicke den Link in der Bestätigungs-E-Mail an, die wir Dir geschickt haben. Keine E-Mail bekommen? Fordere eine Neue an.";
      break;
    default:
      if (extendedStatus) {
        title = `Fehler ${status}: ${extendedStatus}`;
        message = message || `Der Server hat den Fehler-Code ${status} (${extendedStatus}) zurückgegeben.`;
      } else if(status && response?.statusText) {
        title = `Fehler ${status}: ${response.statusText}`;
        message = message || `Der Server hat den Fehler-Code ${status} (${response.statusText}) zurückgegeben.`;
      }

      break;
  }

  return { status, extendedStatus, title, message, validationErrors, validationMessage, isValidationError };
};

const errorToComponent = (response) => {
  const { title, message } = parseXHRError(response);

  return (
    <Callout
      calloutClass="danger"
      title={title}
      text={message}
    />
  );
}

const errorToToast = (response, whileInfo = null) => {
  const { title, isValidationError, validationMessage } = parseXHRError(response);

  if(isValidationError) {
    return toast.error(`Ungültige Anfrage: ${validationMessage}`, {autoClose: false});
  }

  if(whileInfo)
    return toast.error(`${title} ${whileInfo} 😢`, {autoClose: false});

  toast.error(title, {autoClose: false});
}

const fetchData = async (namespace, resource_name, params) => {
  const response = await $.ajax({
    url: `/${namespace}/${resource_name}.json`,
    data: params,
    method: 'get',
    dataType: 'json',
  })

  return response;
};

// This function takes a component...
function withLoading(WrappedComponent, {key_name = null, url = null} = {}) {
  // ...and returns another component...
  class Loading extends React.Component {
    state = {retries: 0, maxRetries: 20}

    componentDidMount() {
      this.load();
    }

    componentDidUpdate(prevProps) {
      if(this.props.url != prevProps.url) {
        this.setState({serverStatus: null, data: null, restries: 0, secondsToRetry: null, success: false});
        this.load();
      }
    }

    componentWillUnmount() {
      this.abortLoading();
      if(this.interval)
        clearInterval(this.interval);
    }

    refresh = (silent = false) => {
      if(!silent)
        this.setState({success: false});
      this.abortLoading();
      if(this.interval)
        clearInterval(this.interval);

      this.load();
    }

    changePage = (page) => {
      // get the current browser url
      const url = new URL(window.location.href);
      url.searchParams.set('page', page);
      // set the new url to the browser without triggering a reload
      window.history.pushState({}, '', url);

      const ajax_url = new URL(this.props.url, window.location.origin);
      ajax_url.searchParams.set('page', page);

      this.load(ajax_url);
    }

    load = (new_url = null) => {
      this.ajax = $.ajax({ url: (new_url || this.props.url || url), method: 'get', dataType: 'json'});

      this.ajax.done((data) => {
        const data_key = this.props.key_name || key_name;
        this.setState({ serverStatus: null, success: true, data: data_key ? data[data_key] : data });
      });

      this.ajax.fail((xhr) => {
        if(xhr.status == 404 || xhr.status == 401 || xhr.status == 402) {
          return this.setState({serverStatus: xhr.status, secondsToRetry: null})
        }

        if(xhr.status == 403) {
          try {
            var json_response = JSON.parse(xhr.responseText);

            if(json_response.status_code)
              return this.setState({serverStatus: json_response.status_code, secondsToRetry: null})
          } catch(e) {
            // nothing to see here
          }
        }

        this.setState(
          (prevState) => {
            const backoff = Math.pow(prevState.retries, 2) + 3;
            return {serverStatus: xhr.status, secondsToRetry: backoff, retries: prevState.retries + 1}
          },
          () => {
            if(this.state.retries >= this.state.maxRetries) {
              this.setState({secondsToRetry: null})
              return clearInterval(this.interval);
            }

            this.interval = setInterval(() => {
              if(this.state.secondsToRetry > 1) {
                this.setState((prevState) => ({secondsToRetry: prevState.secondsToRetry - 1}))
              } else {
                clearInterval(this.interval);
                this.setState({serverStatus: null, data: null});
                this.load();
              }
            }, 1000);
          }
        );
      });
    }

    abortLoading() {
      if(this.ajax)
        this.ajax.abort();
    }

    render() {
      if (this.state.serverStatus != null && this.state.serverStatus != 200 && this.props.error_handler) {
        const handler_return = this.props.error_handler(this.state.serverStatus, this.state.data);

        if (handler_return) {
          return handler_return;
        }
      }

      if(this.state.serverStatus == 404)
        return <Callout calloutClass="danger" title="404 - Nicht gefunden" text="Die angeforderte Resource wurde nicht gefunden." />

      if(this.state.serverStatus == 401)
        return <Callout calloutClass="danger" title="401 - Unauthorized" text="Deine Sitzung ist abgelaufen oder Du bist nicht mehr eingeloggt. Bitte logge Dich ein!" />

      if(this.state.serverStatus == 402)
        return <Callout calloutClass="danger" title="402 - Webhosting-Paket erforderlich" text="Für diese Funktion ist ein Webhosting-Paket notwendig, in Deinem jetzigen Vertrag ist diese Funktion nicht enthalten. Bitte starte einen 14-tägigen kostenlosen Test oder buche ein Webhosting-Paket." />

      if(this.state.serverStatus == 'shared_hosting_required')
        return <Callout title="Webhosting-Paket nötig" text='Auf Deinem Account ist derzeit kein Webhosting-Paket vorhanden. Bitte füge ein Webhosting-Paket unter "Webhosting-Paket" hinzu.' />

      if(this.state.serverStatus == 'email_unconfirmed')
        return <Callout calloutClass="danger" title="Account-Aktivierung erforderlich">
            <p>Für diese Funktion ist die Aktivierung Deines Accounts notwendig. Bitte klicke den Link in der Bestätigungs-E-Mail an, die wir Dir geschickt haben.</p>
            <p>Keine E-Mail bekommen? <a href="/usercp/email-adresse">Fordere eine Neue an</a>.</p>
          </Callout>

      if(this.state.serverStatus)
        return <div><ServerErrorCallout responseCode={this.state.serverStatus} /> <span>Reload in {this.state.secondsToRetry}s ({this.state.retries} Versuche...)</span></div>;

      if(!this.state.success)
        if(this.props.spinner_title)
          return <div><PageHeader text={this.props.spinner_title}/> <LoadingTableSpinner /></div>;
        else if(this.props.inline_spinner)
          return <InlineSpinnner />;
        else if (this.props.custom_spinner)
          return this.props.custom_spinner;
        else
          return <LoadingTableSpinner />;

      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return <WrappedComponent data={this.state.data} statusCode={this.state.serverStatus} refreshCb={this.refresh} changePage={this.changePage} {...this.props} />;
    }
  }

  Loading.displayName = `withLoading(${WrappedComponent.name})`;

  return Loading;
}

const transform_data = (type, content) => {
  if(type == 'datetime')
    return content ? format_date(content) : '-';

  if(type == 'filesize')
    return content ? content.fileSize() : '-';

  return content;
}

const stringify_error = (xhr, text_status, error_thrown) => {
  if (text_status == 'error' && error_thrown == 'Bad Request') {
    if (xhr.status == 400) {
      try {
        var json_response = JSON.parse(xhr.responseText);
        if (json_response.errors && Object.keys(json_response.errors).length) {
          return Object.keys(json_response.errors).map((key) => {
            return `"${key}" ${json_response.errors[key].join(', ')}`;
          }).join(', ');
        }

        return "Ungültige Anfrage, aber der Server hat keine Fehler zurückgemeldet"
      } catch (e) {
        return `Allgemeiner XHR-Fehler: readyState ${xhr.readyState} status ${xhr.status} statusText ${xhr.statusText}`;
      }
    }
  }

  if (text_status == "timeout") {
    return "Zeitüberschreitung";
  }

  if (!text_status)
    return `Allgemeiner XHR-Fehler: readyState ${xhr.readyState} status ${xhr.status} statusText ${xhr.statusText}`;

  return `${text_status}, Fehler-Status ${xhr.status}`;
}

/* A small wrapper around useQuery that adds a loading spinner and error handling */
const useResource = (key, url, options = {}) => {
  const { data, refetch, isLoading, isError, isRefetching, error, isPreviousData } = useQuery(key, async () => {
    const response = $.ajax({ url, dataType: 'json' });

    return response;
  }, options);

  if (isLoading) {
    if(options.header) {
      return {
        data: null, refetch, isRefetching, isPreviousData, component: (
        <>
          {options.header}

          <LoadingTableSpinner />
        </>
      )}
    }

    return { data: null, refetch, isRefetching, isPreviousData, component: <LoadingTableSpinner />};
  }

  if (isError) {
    if (options.header) {
      return {
        data: null, refetch, isRefetching, isPreviousData, isError, error, component: (
        <>
          {options.header}

          {errorToComponent(error)}
        </>
      )}
    }

    return { data: null, refetch, isRefetching, isPreviousData, isError, error, component: errorToComponent(error) }
  }

  return { data, refetch, isRefetching, isLoading, isError, error, isPreviousData };
}

export { errorToComponent, errorToToast, fetchData, parseXHRError, stringify_error, transform_data, useResource, withLoading };

