import { binToStr, strToBin } from 'client/js/util/base64';
import { FaIcon } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { stringify_error } from 'client/js/util/rest_utils';
import Modal from 'react-modal';

const Error = (props) => (<div className="text-center">
    <h5 className="text-danger">
      <FaIcon name="exclamation-circle fa-4x mb-2" />
      <br />
      Es ist ein Fehler aufgreteten
    </h5>

    <p>Die Fehlermeldung lautet: <br/> {props.error}</p>

    {!!props.retry && <button className="btn-secondary btn" onClick={props.retry}><FaIcon name="refresh" /> Erneut versuchen</button>}

    {props.moreFactors ? <p className="text-center mt-3"><a href="#" onClick={props.chooseFactor}>einen andere Authentifizierungsmethode wählen</a></p> : null}
  </div>)

const Success = () => (<div className="text-center">
    <h5 className="text-success">
      <FaIcon name="check-circle fa-4x mb-2" />
      <br />
      Authentifiziert!
    </h5>

    <LoadingTableSpinner text="Weiterleiten..." />
  </div>)

class OtpAuthentication extends React.Component {
  state = {code: ""}

  componentDidMount() {
    this.codeInput && this.codeInput.focus();
  }

  submit = (e) => {
    e && e.preventDefault();
    this.setState({loading: true});
    $.post(`/usercp/check-mfa/otp/challenge.json`, {response: {code: this.state.code}, token: this.props.token}).done((data) => {
      this.setState({loading: false, success: true});
      this.props.onSuccess();
    }).fail(this.handleXhrFail);
  }

  handleChange = (e) => {
    this.setState({code: e.target.value})
  }

  retry = (e) => {
    e && e.preventDefault();
    this.setState({code: "", loading: false, error: null});
  }

  handleXhrFail = (xhr, y, z) => {
    console.log('XHR failed:', xhr, y, z)
    if(xhr.status == 400){
      try {
        var json_response = JSON.parse(xhr.responseText);
        if(json_response?.errors?.code) {
          return this.setState({loading: false, code_error: json_response.errors.code.join(', ')})
        }
      } catch(e) {
        // do nothing
      }
    }

    this.setState({code: "", error: stringify_error(xhr, y, z), loading: false});
  }

  render() {
    if(this.state.error)
      return <div className="modal-body"><Error error={this.state.error} retry={this.retry} /></div>;

    if(this.state.success)
      return <div className="modal-body"><Success /></div>;

    return <form onSubmit={this.submit}>
            <div className="modal-body">
              <p>Bitte öffne nun die Authenticator-App und gebe den Code für "lima-city" ein:</p>

              <input type="number" min="100000" max="999999" step="1"
                      className={this.state.code_error ? "form-control form-control-lg is-invalid text-center" : "form-control form-control-lg text-center"}
                      onChange={this.handleChange} value={this.state.code}
                      disabled={this.state.loading} ref={(input) => { this.codeInput = input; }} />
              {!!this.state.code_error && <p className="invalid-feedback">{this.state.code_error}</p>}

              {this.props.moreFactors ? <p className="text-center mt-3"><a href="#" onClick={this.props.chooseFactor}>einen andere Authentifizierungsmethode wählen</a></p> : null}
              </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={this.submit} disabled={this.state.loading}><FaIcon name={this.state.loading ? 'spinner spin' : 'key'}/> Authentifizieren</button>
            </div>
          </form>
  }
}

class WebauthnAuthentication extends React.Component {
  state = {retry_counter: 0}

  componentDidMount() {
    if(navigator.credentials)
      this.loadChallenge();
    else
      this.setState({credentials_unavailable: true});
  }

  loadChallenge = () => {
    $.ajax(`/usercp/check-mfa/webauthn/challenge.json`).done((data) => {
      data.challenge.challenge = strToBin(data.challenge.challenge);
      data.challenge.allowCredentials = data.challenge.allowCredentials.map((el) => {
        el.id = strToBin(el.id)
        return el;
      });

      this.setState({challenge: data.challenge}, () => this.startAuthentication());
    }).fail(this.handleXhrFail);
  }

  startAuthentication = () => {
    navigator.credentials.get({publicKey: this.state.challenge}).then((authentication) => {
      this.setState({checking: true});
      const response = {
        id: authentication.id,
        rawId: binToStr(authentication.rawId),
        type: authentication.type,
        response: {
          authenticatorData: binToStr(authentication.response.authenticatorData),
          clientDataJSON: binToStr(authentication.response.clientDataJSON),
          signature: binToStr(authentication.response.signature),
          sign_count: authentication.response.sign_count
        }
      }

      $.post(`/usercp/check-mfa/webauthn/challenge.json`, {response: response, token: this.props.token}).done(() => {
        this.setState({success: true});
        this.props.onSuccess();
      }).fail(this.handleXhrFail);
    }).catch((error) => {
      if (
          error instanceof Error &&
          error.name == "NotAllowedError" &&
          error.message == "The document is not focused."
      ) {
        // we need to retry in a few seconds. This is a workaround for Safari.
        // we retry for max 5 times, keeping a counter in the state
        if(this.state.retry_counter < 5) {
          this.setState({retry_counter: this.state.retry_counter + 1});

          return setTimeout(() => this.startAuthentication(), 500);
        }
      }

      this.setState({challenge: null});

      if(error)
        return this.setState({error: error.toLocaleString("de-DE")});

      this.setState({error: "Fehler bei der Authentifizierung"});
    })
  }

  retry = (e) => {
    e.preventDefault();
    this.setState({challenge: null, error: null, checking: false});
    this.loadChallenge();
  }

  handleXhrFail = (x, y, z) => {
    console.log('XHR failed:', x, y, z)
    this.setState({error: stringify_error(x, y, z)});
  }

  render() {
    if(this.state.success)
      return <Success />;

    if(this.state.credentials_unavailable)
      return <Error error="Der Browser oder die Umgebung unterstützt keine Sicherheitsschlüssel" moreFactors={this.props.moreFactors} chooseFactor={this.props.chooseFactor} />;

    if(this.state.error)
      return <Error retry={this.retry} error={this.state.error} moreFactors={this.props.moreFactors} chooseFactor={this.props.chooseFactor} />;

    if(this.state.challenge) {
      return (
        <div className="text-center">
          <p>Drücke nun - sofern vorhanden - die Taste zur Bestätigung auf dem Sicherheitsschlüssel.</p>

          <LoadingTableSpinner text="Warte auf den Bestätigung..." />

          {this.props.moreFactors ? <p className="text-center mt-3"><a href="#" onClick={this.props.chooseFactor}>eine andere Authentifizierungsmethode wählen</a></p> : null}
        </div>
      );
    }

    if(this.state.checking) {
      return (
        <div className="text-center">
          <p>Die Authentifizierung wird nun geprüft.</p>

          <LoadingTableSpinner text="Überprüfen..." />
        </div>
      );
    }

    return (
      <>
        <p className="text-center">Bitte stecke den Sicherheitsschlüssel in einen USB-Port...</p>
        <LoadingTableSpinner text="Abrufen der Challenge..." />

        {this.props.moreFactors ? <p className="text-center mt-3"><a href="#" onClick={this.props.chooseFactor}>eine andere Authentifizierungsmethode wählen</a></p> : null}
      </>
      );
  }
}

const Verify = (props) => {
  if (props.type == 'webauthn')
    return (
      <div className="modal-body">
        <WebauthnAuthentication {...props} />
      </div>
      );

      return <OtpAuthentication {...props} />
}

class Authentication extends React.Component {
  state = {}

  componentDidMount() {
    if (!this.state.loaded) {
      this.loadFactors();
      return;
    }

    let type = 'otp';
    if (this.props.webauthn_available)
      type = 'webauthn';

    this.setState({ type: type, otp_available: this.props.otp_available, webauthn_available: this.props.webauthn_available });
  }

  loadFactors = () => {
    $.ajax('/usercp/check-mfa.json').done((data) => {
      // if webauthn is available, we use it, otherwise we use otp
      let type = 'otp';
      if(data.webauthn_available)
        type = 'webauthn';

      this.setState({ loaded: true, type: type, otp_available: data.otp_available, webauthn_available: data.webauthn_available });
    }).fail(this.handleXhrFail);
  }

  handleXhrFail = (x, y, z) => {
    console.log('XHR failed:', x, y, z)
    this.setState({error: stringify_error(x, y, z)});
  }

  retry = (e) => {
    e.preventDefault();
    this.setState({error: null});
  }

  selectType = (e, type) => {
    e.preventDefault();
    this.setState({type: type});
  }

  chooseFactor = (e) => {
    e.preventDefault();
    this.setState({type: null});
  }

  render() {
    if(this.state.error)
      return <div className="modal-body"><Error error={this.state.error} retry={this.retry} /></div>;

    if(this.state.type) {
      return <Verify type={this.state.type} onSuccess={this.props.onSuccess} moreFactors={this.state.otp_available && this.state.webauthn_available} chooseFactor={this.chooseFactor} token={this.props.token} />
    } else if(this.state.loaded) {
      return (
        <div className="modal-body">
          <div className="list-group list-group-flush">
            <a href="#" className="list-group-item list-group-item-action" onClick={(e) => this.selectType(e, 'webauthn')}>
              <h5 className="mb-1">WebAuthn/Hardware-Key</h5>
              <p>Hardware-Schlüssel, Windows Hello oder biometrischer Login</p>
            </a>
            <a href="#" className="list-group-item list-group-item-action" onClick={(e) => this.selectType(e, 'otp')}>
              <h5 className="mb-1">Authenticator App</h5>
              <p>One-Time-Password mit Authenticator App wie Aegis, 2FAS, etc.</p>
            </a>
          </div>
        </div>
        );
    }

    return <div className="modal-body"><LoadingTableSpinner /></div>;
  }
}

class AuthenticateModal extends React.Component {
  state = {open: true}

  success = () => {
    if(this.props.onSuccess) {
      this.setState({open: false})
      return this.props.onSuccess();
    }

    if(this.props.return_url)
      return window.location.href = this.props.return_url;

    const hash_url = window.location.hash.replace('#!', '');

    if(hash_url)
      window.location.href = hash_url;
    else
      window.location.href = '/usercp';
  }

  handleAbort = (e) => {
    e.preventDefault();
    this.props.onAbort();
  }

  render() {
    return (
      <Modal isOpen={this.state.open} className="modal fade in show d-block" contentLabel="Multi-Faktor-Authentifizierung">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Authentifizierung</h3>
              {!!this.props.onAbort && <button type="button" className="close" onClick={this.handleAbort} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>}
            </div>

            <Authentication otp_available={this.props.otp_available} webauthn_available={this.props.webauthn_available} onSuccess={this.success} token={this.props.token} />
          </div>
        </div>
      </Modal>
      );
  }
}

export default AuthenticateModal;
