import NewMfaSession from 'client/js/new_mfa_session';
import Callout from 'client/js/util/callout';
import { ControlledInput } from "client/js/util/form_tags";
import { FaIcon } from 'client/js/util/layout_utils';
import PropTypes from "prop-types";
import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

interface ControlErrorsProps {
  errors: string[];
  errorClass?: string;
}

const ControlErrors: React.FC<ControlErrorsProps> = (props) => {
  if(Array.isArray(props.errors) && props.errors.length)
    return (<span className={props.errorClass || 'invalid-feedback d-block'}>{props.errors.join(", ")}</span>);
  else
    return null;
}

ControlErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

var ErrorCallout = function(props) {
  if(Array.isArray(props.errors) && props.errors.length)
    return <Callout calloutClass="danger" title="Ungültige Auswahl" text={props.errors.join(", ")} />;
  else
    return null;
}

ErrorCallout.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

var ControlLabel = function(props) {
  let classes = [props.type, 'col-sm-3 col-form-label'];

  if(props.required)
    classes = classes.concat(['required']);

  return <label className={classes.join(' ')} htmlFor={props.labelFor}>
      {props.required ? <abbr title="erforderlich">*</abbr> : null} {props.label}
    </label>;
}

const ControlGroup = function(props) {
  const hasErrors = props.errors && props.errors.length;
  let n = `row form-group ${props.type} ${props.name} ${hasErrors ? 'error' : ""}`;

  return (<div className={n}>
            {props.label && <ControlLabel label={props.label} labelFor={props.labelFor} required={props.required} />}
            <div className={props.label ? "col-md-9" : "col-md-9 offset-md-3"}>
              {props.children}
              {hasErrors ? <ControlErrors errors={props.errors} errorClass={props.errorClass} /> : null}
              {props.hint ? <div className="form-text">{props.hint}</div> : null}
            </div>
          </div>);
}

var ReactForm = function(props) {
  if(props.unarmed)
    return <div className="form-horizontal form-large-inputs">{props.children}</div>;

  return <form className="form-horizontal form-large-inputs" method="post" action={props.url} onSubmit={e => props.dispatch("submit", e)}>
          {props.children}
         </form>;
}

var ServerErrorCallout = function(props) {
  let text = "Der Server hat einen Fehler zurückgegeben. Dies deutet auf einen Programmierfehler hin. Bitte wende Dich an unseren Support, wenn das Problem dauerhaft auftritt.";
  let title = "Server-Fehler" ;

  if(props.responseCode === 0) {
    text = "Der Browser hat die Anfrage an den Server aufgrund eines Fehlers oder Pre-Flight-Checks nicht ausgeführt. Dies ist in aller Regel ein Netzwerk-Problem.";
  }

  if(props.responseCode == 429) {
    text = "Du hast zu viele Anfragen an den Server gesendet und wurdest limitiert. Bitte versuche es in später noch einmal.";
  }

  if(props.responseCode == 401) {
    text = "Deine Sitzung ist abgelaufen. Bitte logge Dich erneut ein!";
  }

  if(props.responseCode == 403) {
    title = 'Fehler 403 - Forbidden';
    text = "Der Server hat die Bearbeitung der Anfrage mit dem Fehler-Code 'Forbidden' abgelehnt.";
  }

  if(props.responseCode == 503 || props.responseCode == 504 || props.responseCode == 502) {
    text = "Der Server hat einen temporären Fehler (Wartungsarbeiten, temporäre Überlastung) zurückgegeben. Bitte versuche es in wenigen Sekunden erneut.";
  }

  return <Callout calloutClass="danger" title={title} text={text} />;
}

var SubmitButton = function(props: SimpleSubmitButtonProps, context: {submitting: boolean}) {
  return <SimpleSubmitButton submitting={context.submitting} {...props} />
}

SubmitButton.contextTypes = {
  submitting: PropTypes.bool,
  responseCode: PropTypes.number
};

const SubmitIcon = (props) => {
  if(props.submitting)
    return <div className="css-spinloader"></div>;

  if(props.icon)
    return <FaIcon name={`${props.icon} fa-fw`} />

  return null;
}

interface SimpleSubmitButtonProps {
  className?: string;
  submitting?: boolean;
  disabled?: boolean;
  icon?: string;
  text: string;
}

var SimpleSubmitButton: React.FC<SimpleSubmitButtonProps> = function(props) {
  return (
    <button className={props.className} disabled={props.submitting || props.disabled} type="submit">
      <SubmitIcon icon={props.icon} submitting={props.submitting} /> {props.text}
    </button>
  );
};

SimpleSubmitButton.defaultProps = {
  className: "btn btn-primary"
}

function build_name(type, required, extraClass) {
  return ["form-control", type, required, extraClass].filter((n) => (n != undefined && n != null)).join(" ");
}

var i18n_hc = {
  "Use a few words, avoid common phrases": 'Benutze ein paar Wörter, aber keine gängigen Phrasen',
  "No need for symbols, digits, or uppercase letters": "Sonderzeichen, Ziffern und Großbuchstaben helfen nicht viel",
  'Add another word or two. Uncommon words are better.':  "Füge ein, zwei Wörter hinzu, am Besten ungewöhnliche",
  'Straight rows of keys are easy to guess':  "Tastenfolgen auf der Tastatur sind einfach zu erraten",
  'Short keyboard patterns are easy to guess':  "Kurze Tastenfolgen auf der Tastatur sind einfach zu erraten",
  'Use a longer keyboard pattern with more turns':  "Benutze eine längere Tastenfolge mit mehreren Richtungswechseln",
  'Repeats like "aaa" are easy to guess':  "Wiederholungen wie \"aaa\" sind laaaaangweilig",
  'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':  "Wiederholungen wie \"abcabcabc\" sind laaaaangweilig",
  'Avoid repeated words and characters':  "Vermeide Wiederholungen von Wörtern und Buchstaben",
  "Sequences like abc or 6543 are easy to guess": "Zeichenfolgen wie 6543 oder abc sind einfach zu erraten",
  'Avoid sequences':  "Vermeide Zeichenfolgen",
  "Recent years are easy to guess": "Jahreszahlen sind einfach zu erraten",
  'Avoid recent years':  "Vermeide Jahreszahlen aus der jüngsten Vergangenheit",
  'Avoid years that are associated with you':  "Vermeide Jahreszahlen, die mit Dir in Verbindung stehen",
  "Dates are often easy to guess": "Ein Datum ist einfach zu erraten",
  'Avoid dates and years that are associated with you':  "Vermeide Daten und Jahreszahlen, die mit Dir in Verbindung stehen",
  'This is a top-10 common password':  "Dies ist eins der 10 meistgenutzten Passwörter",
  'This is a top-100 common password':  "Dies ist eins der 100 meistgenutzten Passwörter",
  'This is a very common password':  "Dies ist ein sehr häufig genutztes Passwort",
  'This is similar to a commonly used password':  "Ähnelt einem häufig genutzten Passwort",
  'A word by itself is easy to guess':  "Ein einzelnes Wort ist einfach zu raten",
  'Names and surnames by themselves are easy to guess':  "Namen und Orte (für sich) sind einfach zu erraten",
  'Common names and surnames are easy to guess':  "Namen und Orte sind einfach zu erraten",
  "Capitalization doesn't help very much": "Großschreibung hilft da auch nicht viel",
  "All-uppercase is almost as easy to guess as all-lowercase": "Vollständige Großschreibung ist einfach zu erraten",
  "Reversed words aren't much harder to guess": "Ein Wort rückwärts ist einfach zu erraten",
  "Predictable substitutions like '@' instead of 'a' don't help very much": "Einfache Ersatzungen wie \"@\" für \"a\" sind einfach zu erraten"
}

function translate_or_fallback(text) {
  if(i18n_hc[text])
    return i18n_hc[text];
  else
    return text;
}

class PasswordInput extends React.Component {
  state = {current_type: this.props.type}

  componentDidMount() {
    if(window.zxcvbn)
      return;

    $.getScript('/assets/zxcvbn.js');
  }

  toggleType = (e) => {
    e.preventDefault();
    this.setState((state) => ({
      current_type: state.current_type == 'password' ? 'text' : 'password'
    }));
  }

  render() {
    const scores = ['viel zu einfach zu erraten', 'einfach zu erraten', 'fast akzeptabel', 'sicher', 'sehr sicher'];

    const n = [this.context.namespace, this.props.name].filter((n) => (n != undefined)).join('_');
    const errors = this.context.serverErrors[this.props.name];

    const pw_given = this.context.formData && this.context.formData[this.props.name];

    let suggestions = null;

    let progress_class = "";
    let warning = "";
    let strength = null;
    let ok = null;
    let score = 0;
    let checked = false;
    if(window.zxcvbn && pw_given) {
      const res = window.zxcvbn(this.context.formData[this.props.name]);
      score = res.score;
      checked = true;

      strength = scores[res.score];

      if(res.feedback.warning)
        warning = <div className="invalid-feedback"><strong>{translate_or_fallback(res.feedback.warning)}!</strong></div>;

      if(score >= 3) {
        ok = true;
        progress_class = 'bg-success';
      } else if(score == 2) {
        progress_class = 'bg-warning';
      } else {
        progress_class = 'bg-danger';
      }

      if(res.feedback.suggestions.length) {
        suggestions = <small className="pl-2 text-muted"><strong>Tipp</strong>: {translate_or_fallback(res.feedback.suggestions[0])}</small>;
      }
    }

    return (
      <ControlGroup type="password_with_strength"
        name={n}
        labelFor={n}
        required={this.props.required}
        errors={errors}
        label={this.props.label}
        hint={this.props.hint}>
          <ControlledInput name={this.props.name} type={this.state.current_type} required={this.props.required} extraClass={this.props.extraClass}
            placeholder={this.props.placeholder} id={n} autoComplete={this.props.autoComplete}
            hasError={checked ? !ok : false} />
          <FaIcon name="eye toggle-type" onClick={this.toggleType}/>

          {warning}
          <div className="strengthometer">
            Stärke: <span className="text-strength">{strength} {suggestions}</span>
            <div className="progress">
              <div className={`progress-bar ${progress_class} ${score > 0 ? 'filled' : ''}`}></div>
              <div className={`progress-bar ${progress_class} ${score > 1 ? 'filled' : ''}`}></div>
              <div className={`progress-bar ${progress_class} ${score > 2 ? 'filled' : ''}`}></div>
              <div className={`progress-bar ${progress_class} ${score > 3 ? 'filled' : ''}`}></div>
            </div>
          </div>
      </ControlGroup>);
  }
}

PasswordInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  extraClass: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string
};

PasswordInput.defaultProps = {
  type: 'password',
  autoComplete: 'new-password'
};

PasswordInput.contextTypes = {
  namespace: PropTypes.string,
  serverErrors: PropTypes.object,
  formData: PropTypes.object,
  formDispatch: PropTypes.func
};

interface StringInputProps {
  type: string;  // required
  name: string;  // required
  label?: string;
  hint?: string;
  required?: boolean;
  extraClass?: string;
  tabIndex?: string;
  placeholder?: string;
  autoComplete?: string;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

interface StringInputContext {
  namespace?: string;
  serverErrors: Record<string, Array<string>>;
  formData?: Record<string, any>;
  formDispatch?: (action: string, event: any, details: any) => void;
}

const StringInput: React.FC<StringInputProps> = (props, context: StringInputContext) => {
  const n = [context.namespace, props.name].filter((n) => (n != undefined)).join('_');
  const errors = context.serverErrors[props.name];

  return (
    <ControlGroup
      type={props.type}
      name={n}
      labelFor={n}
      required={props.required}
      errors={errors}
      label={props.label}
      hint={props.hint}
    >
      <ControlledInput
        name={props.name}
        type={props.type}
        required={props.required}
        extraClass={props.extraClass}
        placeholder={props.placeholder}
        id={n}
        autoComplete={props.autoComplete}
        min={props.min}
        max={props.max}
        maxLength={props.maxLength}
        step={props.step}
        tabIndex={props.tabIndex}
        autoFocus={props.autoFocus}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
    </ControlGroup>
  );
};

StringInput.defaultProps = {
  type: 'text'
};

StringInput.contextTypes = {
  namespace: PropTypes.string,
  serverErrors: PropTypes.object,
  formData: PropTypes.object,
  formDispatch: PropTypes.func
};

class IbanInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ""};

    this.restrictor = this.restrictor.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  restrictor(e) {
    const new_char = String.fromCharCode(e.which);
    const val = this.state.value + new_char;
    var ok = false;

    // Bei den ersten zwei zeichen sind nur buchstaben i.O.
    if (val.length <= 2) {
      ok = /^[A-Za-z]+$/.test(new_char);
    } else if (val.length < 40) {
      // weitere zeichen sind ziffern
      ok = /^[A-Za-z\d]+$/.test(new_char);
    }

    if(!ok)
      e.preventDefault();
  }

  changeHandler(e) {
    const original_value = e.target.value;

    const NON_ALPHANUM = /[^a-zA-Z0-9]/g,
      EVERY_FOUR_CHARS =/(.{4})(?!$)/g;

    const new_iban = original_value.replace(NON_ALPHANUM, '').replace(EVERY_FOUR_CHARS, "$1 ").toUpperCase();

    this.setState({value: new_iban});
    this.context.formDispatch('value_changed', e, {name: this.props.name, value: new_iban});
  }

  render() {
    const props = this.props;
    const n = [this.context.namespace, props.name].filter((n) => (n != undefined)).join('_');
    const field_name = this.context.namespace ? `${this.context.namespace}[${props.name}]` : props.name
    const errors = this.context.serverErrors[props.name];

    return (
      <ControlGroup type={'iban'}
          name={n}
          labelFor={n}
          required={props.required}
          errors={errors}
          label={props.label}
          hint={props.hint}>
        <input value={this.state.value} onKeyPress={this.restrictor} onPaste={this.changeHandler} onChange={this.changeHandler}
        aria-required={props.required} required={props.required}
        className={build_name('iban', props.required, props.extraClass)}
        placeholder={props.placeholder}
        id={n}
        name={field_name} type="text" />
      </ControlGroup>);
  }
}

StringInput.propTypes = {
  name: PropTypes.string.isRequired
};

IbanInput.contextTypes = {
  namespace: PropTypes.string,
  serverErrors: PropTypes.object,
  formDispatch: PropTypes.func
};

var ChangeSelectionInput = function(props, context) {
  const errors = context.serverErrors[props.name];

  return (
    <ControlGroup type={'change_selection'}
        name={props.name}
        errors={errors} errorClass="help-block"
        required={props.required}
        label={props.label}
        hint={props.hint}>
      <strong>{props.children}</strong> <a href="#" onClick={e => context.formDispatch('value_changed', e, {name: props.name, value: null})}>ändern</a>
    </ControlGroup>);
}

ChangeSelectionInput.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string.isRequired
};

ChangeSelectionInput.contextTypes = {
  serverErrors: PropTypes.object,
  formDispatch: PropTypes.func
};

interface SelectCollectionInputProps {
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  clearable: boolean;
  label?: string;
  hint?: string;
  name: string;
  options: Record<string, any> | Array<any>;
  raw_options?: Array<any>;
}

interface SelectCollectionInputContext {
  namespace?: string|null;
  serverErrors: Record<string, Array<string>>;
  formData: Record<string, any>;
  formDispatch: (
    action: string,
    event: any,
    details: { name: string; value: any; label: string | null }
  ) => void;
}

interface SelectCollectionInputState {}

class SelectCollectionInput extends React.Component<
  SelectCollectionInputProps,
  SelectCollectionInputState
> {
  static defaultProps: Partial<SelectCollectionInputProps> = {
    clearable: false,
    disabled: false,
    isLoading: false,
  };

  static contextTypes = {
    namespace: PropTypes.string,
    serverErrors: PropTypes.object,
    formData: PropTypes.object,
    formDispatch: PropTypes.func
  };

  state: SelectCollectionInputState = {}

  context!: SelectCollectionInputContext;

  changeHandler = (e) => {
    if(e === null) {
      this.context.formDispatch('value_changed', e, {name: this.props.name, value: null, label: null});
    } else {
      this.context.formDispatch('value_changed', e, {name: this.props.name, value: e.value, label: e.label});
    }
  }

  render() {
    const context = this.context;
    const props = this.props;

    const n = [context.namespace, props.name].filter((n) => (n != undefined)).join('_');
    const field_name = context.namespace ? `${context.namespace}[${props.name}]` : props.name
    const errors = context.serverErrors[props.name];

    let options = [];
    if(props.raw_options) {
      options = props.raw_options;
    } else {
      if(props.options.constructor === Array) {
        options = props.options;
      } else {
        Object.keys(props.options).map(function(key) {
          options.push([key, props.options[key]]);
        });
      }

      options = options.map((i) => ({value: i[0], label: i[1]}))
    }

    let valueItem = null;

    // find the options value where the value is the same as the current value
    // incomplete solution:
    // valueItem = options.find((i) => (i.value == context.formData[props.name]));
    // if the options entry has a key "options" then it's a group and we need to search their options, too

    if(context.formData && context.formData[props.name]) {
      valueItem = options.find((i) => (i.value == context.formData[props.name]));

      if(!valueItem) {
        const groups = options.filter((i) => (!!i.options));
        groups.forEach((g) => {
          valueItem = g.options.find((o) => (o.value == context.formData[props.name]))
        });
      }
    }

    return (
      <ControlGroup errors={errors} name={n} hint={this.props.hint} type="collection" required={props.required} label={props.label}>
          <Select name={field_name}
                  className="Select"
                  classNamePrefix="Select"
                  noOptionsMessage={() => "Keine Optionen gefunden"}
                  placeholder={`${this.props.label} auswählen` }
                  value={valueItem}
                  options={options}
                  required={this.props.required}
                  isClearable={this.props.clearable}
                  isDisabled={this.props.disabled}
                  isLoading={this.props.isLoading}
                  onChange={this.changeHandler} />
      </ControlGroup>);
  }
}

interface RadioCollectionInputProps {
  required?: boolean;
  label?: string;
  hint?: string;
  name: string;
  options: Record<string, React.ReactNode> | Array<[React.ReactNode, React.ReactNode]>;
  selectedOption?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, formDispatch: any) => void;
}

interface RadioCollectionInputContext {
  namespace?: string|null;
  serverErrors: Record<string, Array<string>>;
  formData: Record<string, any>;
  formDispatch: (
    action: string,
    event: any,
    details: { name: string; value: any; label: string | null }
  ) => void;
}

const RadioCollectionInput = function(props: RadioCollectionInputProps, context: RadioCollectionInputContext) {
  const n = [context.namespace, props.name].filter((n) => (n != undefined)).join('_');
  const field_name = context.namespace ? `${context.namespace}[${props.name}]` : props.name
  const errors = context.serverErrors[props.name];
  let options = [];
  if(props.options.constructor === Array) {
    options = props.options;
  } else {
    Object.keys(props.options).map(function(key) {
      options.push([key, props.options[key]]);
    });
  }

  return (
    <ControlGroup errors={errors} name={n} type="collection" required={props.required} label={props.label} hint={props.hint}>
        {options.map((v) => {
          let _n = `${n}_${v[0]}`;
          const changeHandler = (e) => {
            context.formDispatch('value_changed', e, {name: props.name, value: v[0], label: v[1]});
            if(props.onChange)
              props.onChange(e, context.formDispatch);
          }

          return <div key={v[0]} className="custom-control custom-radio">
            <input defaultChecked={(context.formData[props.name] || props.selectedOption) == v[0]}
                   onChange={changeHandler}
                   required={props.required}
                   className={build_name('custom-control-input collection', props.required, props.extraClass)}
                   id={_n}
                   name={field_name}
                   type="radio"
                   value={v[0]} />
            <label htmlFor={_n} className="custom-control-label">{v[1]}</label>
          </div>;
        })}
    </ControlGroup>);
}

RadioCollectionInput.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
}

RadioCollectionInput.contextTypes = {
  namespace: PropTypes.string,
  serverErrors: PropTypes.object,
  formData: PropTypes.object,
  formDispatch: PropTypes.func
};

var CheckboxCollectionInput = function(props, context) {
  const n = [context.namespace, props.name].filter((n) => (n != undefined)).join('_');
  const field_name = context.namespace ? `${context.namespace}[${props.name}]` : props.name
  const errors = context.serverErrors[props.name];
  let options = [];
  if(props.options.constructor === Array) {
    options = props.options;
  } else {
    Object.keys(props.options).map(function(key, index) {
      options.push([key, props.options[key]]);
    });
  }

  let form_values = [];
  if(context.formData && context.formData[props.name] && context.formData[props.name].length > 0)
    form_values = context.formData[props.name];

  return (
    <ControlGroup errors={errors} name={n} type="collection" required={props.required} hint={props.hint} label={props.label}>
      {options.map((v, index) => {
        let _n = `${n}_${v[0]}`;
        const changeHandler = e => context.formDispatch("boolean_collection_check", e, {field_name: props.name});

        return <div key={v[0]} className="custom-control custom-checkbox checkbox-option">
          <input defaultChecked={props.selectedOption == v[0] || form_values.includes(v[0])}
                 onChange={changeHandler}
                 className={build_name('custom-control-input collection', props.required, props.extraClass)}
                 id={_n}
                 name={field_name}
                 type="checkbox"
                 value={v[0]} />
          <label className="custom-control-label" htmlFor={_n}>{v[1]}</label>
        </div>;
      })}
    </ControlGroup>);
}

CheckboxCollectionInput.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
}

CheckboxCollectionInput.contextTypes = {
  namespace: PropTypes.string,
  serverErrors: PropTypes.object,
  formData: PropTypes.object,
  formDispatch: PropTypes.func
};

var BooleanInput = function(props, context) {
  const n = [context.namespace, props.name].filter((n) => (n != undefined)).join('_');
  const errors = context.serverErrors[props.name];
  const changeHandler = (e) => {
    const newValue = props.sendBoolean ? e.target.checked : (e.target.checked ? '1' : '0')
    context.formDispatch('value_changed', null, {name: props.name, value: newValue})
    return true;
  }

  return (
    <ControlGroup type='boolean' name={n} required={props.required} errors={errors} hint={props.hint}>
      <div className="custom-control custom-checkbox">
        <ControlledInput type="checkbox" name={props.name} value={props.value}
                         disabled={props.disabled}
                         required={props.required} extraClass={props.extraClass ? `custom-control-input ${props.extraClass}` : 'custom-control-input'}
                         id={n} onChange={changeHandler} />

        <label className="custom-control-label" htmlFor={n}>{props.required && <abbr title="erforderlich">*</abbr>} {props.label}</label>
      </div>
    </ControlGroup>);
}

BooleanInput.propTypes = {
  value: PropTypes.string.isRequired,
  sendBoolean: PropTypes.bool
};

BooleanInput.defaultProps = {
  value: "1",
  sendBoolean: false
}

BooleanInput.contextTypes = {
  disabled: PropTypes.bool,
  namespace: PropTypes.string,
  serverErrors: PropTypes.object,
  extraClass: PropTypes.string,
  formDispatch: PropTypes.func
};

var TextInput = function(props, context) {
  const n = [context.namespace, props.name].filter((n) => (n != undefined)).join('_');
  const field_name = context.namespace ? `${context.namespace}[${props.name}]` : props.name
  const errors = context.serverErrors[props.name];
  let extraClass = props.extraClass ? [props.extraClass] : [];

  if(context.fieldsValidated && context.fieldsValidated.includes(props.name))
    extraClass.push(errors && errors.length ? 'is-invalid' : 'is-valid')

  let value = props.value;
  if (context.formData && context.formData.hasOwnProperty(props.name))
    value = context.formData[props.name];

  return (
    <ControlGroup type={'text'}
        name={n}
        labelFor={n}
        required={props.required}
        errors={errors}
        label={props.label}
        hint={props.hint}>
      <textarea value={value} onChange={e => context.formDispatch('value_changed', e, {name: props.name, value: e.target.value})}
      aria-required={props.required} required={props.required}
      className={build_name('text', props.required, extraClass.join(' '))}
      placeholder={props.placeholder}
      rows={props.rows}
      id={n}
      name={field_name} ></textarea>
    </ControlGroup>);
}

TextInput.contextTypes = {
  namespace: PropTypes.string,
  fieldsValidated: PropTypes.array,
  serverErrors: PropTypes.object,
  formData: PropTypes.object,
  formDispatch: PropTypes.func
};

var MultistepReactForm = function(props) {
  return <GenericReactForm unarmed={!!(props.step != props.finalStep)} namespace={props.namespace} url={props.url} dispatch={props.dispatch}>{props.children}</GenericReactForm>;
}

interface GenericReactFormProps {
  defaults?: object|null;
  namespace?: string|null;
  url: string;
  verb?: "GET"|"POST"|"PUT"|"DELETE"|"PATCH"|null;
  multipart?: boolean|null;
  resetOnSuccess?: boolean|null;
  onSuccess?: Function|null;
  onValidationError?: Function|null;
  transform_data?: Function|null;
  dispatch?: Function|null;
  children: React.ReactNode;
}

interface GenericReactFormState {
  // Define state here
  formData: object;
  serverErrors: object;
  submitting: boolean;
  fieldsValidated: string[];
  "2fa_token"?: string;
}

class GenericReactForm extends React.Component<GenericReactFormProps, GenericReactFormState> {
  static childContextTypes = {
    namespace: PropTypes.string,
    verb: PropTypes.string,
    url: PropTypes.string,
    serverErrors: PropTypes.object,
    fieldsValidated: PropTypes.array,
    formData: PropTypes.object,
    submitting: PropTypes.bool,
    formDispatch: PropTypes.func
  };

  state = {formData: (this.props.defaults || {}), serverErrors: {}, submitting: false, fieldsValidated: []}

  getChildContext() {
    return {namespace: this.props.namespace,
            serverErrors: this.state.serverErrors,
            formData: this.state.formData,
            fieldsValidated: this.state.fieldsValidated,
            submitting: this.state.submitting,
            formDispatch: this.dispatch};
  }

  getFormData = () => {
    const server_data = this.props.transform_data ? this.props.transform_data(this.state.formData) : this.state.formData;

    if(this.props.multipart) {
      let form_data = new FormData();
      Object.entries(server_data).forEach((entry) => {
        const [key, value] = entry;
        const namespacedKey = this.props.namespace ? `${this.props.namespace}[${entry[0]}]` : entry[0];

        if(value && value.constructor.name == 'File')
          form_data.append(namespacedKey, value);
        else if(Array.isArray(value) && value.length > 0 && value[0].constructor.name == 'File') {
          for(const file in value)
            form_data.append(`${namespacedKey}[]`, value[file]);
        } else {
          form_data.append(namespacedKey, value);
        }
      });
      return form_data;
    }

    return JSON.stringify(this.props.namespace ? {[this.props.namespace]: server_data} : server_data);
  }

  dispatch = (action, original_event, data) => {
    switch (action) {
      case "value_changed": {
        this.setState((prevState, props) => {
          let s = prevState.formData;
          let f = prevState.fieldsValidated;
          s[data.name] = data.value;

          f = f.filter((item) => (item !== data.name))

          this.props.dispatch && this.props.dispatch('form:change', original_event, {changedField: data.name, formData: s, label: data.label});
          return Object.assign(prevState, {formData: s, fieldsValidated: f});
        });

        break;
      }
      case "boolean_collection_check": {
        let new_state = original_event.target.checked;
        let val = original_event.target.value;
        let name = (data && data.field_name) || original_event.target.name;

        this.setState((prevState, props) => {
          let s = prevState.formData;

          let keys = s[name] || [];
          if(new_state) {
            keys = keys.concat(val);
          } else {
            keys = keys.filter((item) => (item != val))
            // const p = keys.indexOf(val);
            // if(p !== -1)
            //   keys.splice(p, 1);
          }

          s[name] = keys;

          return {formData: s};
        });

        break;
      }
      case "submit": {
        this.setState({submitting: true});
        const verb = this.props.verb || 'post';
        console.log(`GenericReactForm: form submit ${verb} ${this.props.url}`);
        if(original_event) {
          original_event.preventDefault();
          original_event.stopPropagation();
        }

        const submittedData = this.state.formData;
        const xhrOptions = {url: this.props.url,
                            type: verb,
                            dataType: 'json',
                            contentType: "application/json",
                            headers: { 'Content-Type': 'application/json', 'x-2fa-token': this.state['2fa_token']},
                            data: this.getFormData()};

        if(this.props.multipart) {
          xhrOptions.headers = {};
          xhrOptions.processData = false;
          xhrOptions.contentType = false;
        }

        $.ajax(xhrOptions)
          .done((data, x, y) => {
            let validated = Object.keys(submittedData);

            if(y.status == 200 || y.status == 201) {
              // successfully created the new record. call success callback
              if(this.props.resetOnSuccess)
                this.setState({submitting: false});

              this.props.dispatch && this.props.dispatch('form:success', null, data)
              this.props.onSuccess && this.props.onSuccess(data, submittedData);
            } else {
              if(data.errors) {
                // got some error here
                validated = validated.concat(Object.keys(data.errors));

                this.setState({serverErrors: data.errors, submitting: false, fieldsValidated: validated})
                this.props.dispatch && this.props.dispatch('form:validation_errors', null, data)
                this.props.onValidationError && this.props.onValidationError(data, submittedData);
              } else {
                // we have no idea what happened...
                toast.error(`Fehler ${x.status} - Der Server hat einen Fehler zurückgegeben. Bitte versuche es erneut oder wende Dich an unseren Support, wenn das Problem dauerhaft auftritt.`, {autoClose: false});
                this.setState({serverErrors: {}, submitting: false, fieldsValidated: []})
                this.props.dispatch && this.props.dispatch('form:invalid_response', null, x);
              }
            }
          })
          .fail((x) => {
            let validated = Object.keys(this.state.formData);

            if(x.status == 400) {
              let data = null;
              try {
                data = JSON.parse(x.responseText);
              } catch(e) {
                toast.error(`Fehler ${x.status} - Der Server hat einen Fehler zurückgegeben. Bitte versuche es erneut oder wende Dich an unseren Support, wenn das Problem dauerhaft auftritt.`, {autoClose: false});
                this.setState({serverErrors: {}, submitting: false, fieldsValidated: []});
                return;
              }
              if (data.status == '2fa_required') {
                return this.setState({"2fa_token": data['2fa_token']});
              }

              if(data && data.errors) {
                validated = validated.concat(Object.keys(data.errors));

                if (data.errors['base']) {
                  toast.error(`Fehler: ${data.errors['base']}`, {autoClose: false});
                }
              } else {
                validated = []
              }

              this.setState({serverErrors: (data.errors || {}), submitting: false, fieldsValidated: validated});
              this.props.dispatch && this.props.dispatch('form:validation_errors', null, data);
              this.props.onValidationError && this.props.onValidationError(data, submittedData);
            } else {
              if(x.status == 429) { // rate limited
                setTimeout(() => {
                  toast.error(`Fehler ${x.status} - Du hast zu viele Anfragen gesendet und wurdest limitiert. Bitte versuche es in später noch einmal.`, {autoClose: false});
                  this.setState({serverErrors: {}, submitting: false, fieldsValidated: []})
                }, 5000)
              } else {
                toast.error(`Fehler ${x.status} - Der Server hat einen Fehler zurückgegeben. Bitte versuche es erneut oder wende Dich an unseren Support, wenn das Problem dauerhaft auftritt.`, {autoClose: false});
                this.setState({serverErrors: {}, submitting: false, fieldsValidated: []});
              }
              this.props.dispatch && this.props.dispatch('form:server_error', null, x);
            }
          })

        break;
      }
    }
  }

  on2FaSuccess = () => {
    this.dispatch('submit', null, null);
  }

  on2FaAbort = () => {
    this.setState({"2fa_token": null, submitting: false});
  }

  render() {
    return (<ReactForm unarmed={this.props.unarmed} url={this.props.url} dispatch={this.dispatch}>
        {this.props.children}
        {!!this.state['2fa_token'] && <NewMfaSession token={this.state['2fa_token']} onSuccess={this.on2FaSuccess} onAbort={this.on2FaAbort} />}
      </ReactForm>);
  }
}

GenericReactForm.propTypes = {
  transform_data: PropTypes.func,
  resetOnSuccess: PropTypes.bool,
}

GenericReactForm.defaultProps = {
  resetOnSuccess: true
}

const FormActions = (props) => (
  <div className="row">
    <div className="form-actions offset-md-3 col-md-9">
      {props.children}
    </div>
  </div>
)

export {
  BooleanInput, ChangeSelectionInput, CheckboxCollectionInput, ControlErrors, ControlGroup, ControlLabel,
  ErrorCallout, FormActions, GenericReactForm, IbanInput, MultistepReactForm, PasswordInput, RadioCollectionInput, ReactForm, SelectCollectionInput, ServerErrorCallout, SimpleSubmitButton, StringInput, SubmitButton, TextInput
};

