import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Popover, PopoverBody, PopoverHeader, Tooltip } from 'reactstrap';

const BreadcrumbItem = (props) => {
  if(props.active)
    return <li className="breadcrumb-item active" aria-current='page'>{props.text}</li>;

  return <li className="breadcrumb-item"><Link to={props.href || '#'}>{props.text}</Link></li>;
}

BreadcrumbItem.defaultProps = {
  active: false
}

BreadcrumbItem.propTypes = {
  href: PropTypes.string,
  text: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired
};

const ResourceBreadcrumbs = (props) => {
  if(props.action == 'show') {
    return <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <BreadcrumbItem href={`/${props.resource}`} text={i18n_t(`resources.${props.resource}.index.header`)} />
          <BreadcrumbItem active text={props.text} />
        </ol>
      </nav>
  } else if(props.action == 'index') {
    return <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <BreadcrumbItem active text={i18n_t(`resources.${props.resource}.index.header`)} />
        </ol>
      </nav>
  } else if(props.action == 'create') {
    return <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <BreadcrumbItem href={`/${props.resource}`} text={i18n_t(`resources.${props.resource}.index.header`)} />
          <BreadcrumbItem active text={i18n_t(`resources.${props.resource}.create`)} />
        </ol>
      </nav>
  } else if(props.action == 'edit') {
    return <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <BreadcrumbItem href={`/${props.resource}`} text={i18n_t(`resources.${props.resource}.index.header`)} />
          <BreadcrumbItem href={`/${props.resource}/${props.id}`} text={props.text || `${i18n_t(`resources.${props.resource}.singular`)} ${props.id}`} />
          <BreadcrumbItem active text={i18n_t(`resources.${props.resource}.edit`)} />
        </ol>
      </nav>
  }

  return <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <BreadcrumbItem href={`/${props.resource}`} text={i18n_t(`resources.${props.resource}.index.header`)} />
          <BreadcrumbItem href={`/${props.resource}/${props.id}`} text={props.text || `${i18n_t(`resources.${props.resource}.singular`)} ${props.id}`} />
          <BreadcrumbItem active text={i18n_t(`resources.${props.resource}.interactions.${props.action}`)} />
        </ol>
      </nav>
}


ResourceBreadcrumbs.propTypes = {
  action: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  id: PropTypes.string,
  text: PropTypes.node
};

  // resource="customers" action="show" name={props.data.username}

const Card = (props) => (
  <div className={props.cardClass}>
    {props.title !== false && <h5 className="card-header">{props.title}</h5>}
    {props.children}
  </div>
)

Card.propTypes = {
  cardClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
}

Card.defaultProps = {
  cardClass: "card mb-3"
}

const CardList = (props) => (
  <ul className="list-group list-group-flush">
    {props.children}
  </ul>
)

Card.propTypes = {
  children: PropTypes.element.isRequired
}

const CardListItem = (props) => (
  <li className="list-group-item">
    {props.title}
    {(props.text || props.children) && <div className="float-right">{props.text || props.children}</div>}
  </li>
)

CardListItem.propTypes = {
  title: PropTypes.node.isRequired,
  text: PropTypes.node,
  children: PropTypes.node
}

const BackSvg = () => (<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="34px" height="21px" viewBox="0 0 34 21" style={{enableBackground: 'new 0 0 34 21'}} xmlSpace="preserve">
  <path className="bb" d="M32.1,8.9H5.7l6.1-6.1c0.6-0.6,0.6-1.7,0-2.3c-0.6-0.6-1.7-0.6-2.3,0L0.7,9.4c-0.6,0.6-0.6,1.6,0,2.3l8.8,8.9
      c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.2,1.1-0.5c0.6-0.6,0.6-1.6,0-2.3l-6.1-6.1h26.4c0.9,0,1.6-0.7,1.6-1.6
      C33.8,9.6,33,8.9,32.1,8.9z" />
  </svg>);

const BackLink = (props) => {
  if(props.regular_link)
    return <a href={props.back_url} className="back-link" title="zurück"><BackSvg /></a>;

  return <Link to={props.back_url} className="back-link" title="zurück"><BackSvg /></Link>;
};

BackLink.propTypes = {
  regular_link: PropTypes.bool,
  back_url: PropTypes.string.isRequired
};

const PageHeader = ({ text, back_url, regular_link, buttons, usage, children }) => {
  useEffect(() => {
    if (text) {
      document.title = `${text} | lima-city`;
    }
  }, [text]);

  return (
    <div className="page-header">
      <h1>
        {!!back_url && <BackLink back_url={back_url} regular_link={regular_link} />}
        {' '}
        {children || text}
      </h1>
      {usage}
      {buttons && (
        <div className="resource-btns">
          {buttons}
        </div>
      )}
    </div>
  );
};

PageHeader.propTypes = {
  regular_link: PropTypes.bool,
  buttons: PropTypes.node,
  text: PropTypes.string.isRequired,
  back_url: PropTypes.string
}

PageHeader.defaultProps = {
  regular_link: false
}

class HelpQuestionMark extends React.Component {
  state = {open: false, popover_id: Math.floor(Math.random() * 1000000000)}

  triggerStart = () => {
    this.setState({open: true});
  }

  triggerEnd = () => {
    this.setState({open: false});
  }

  render() {
    return <span className="mx-1">
            <FaIcon id={`Popover-${this.state.popover_id}`} name={`${this.props.icon_name || 'info-circle'} icon-2x help-question-mark`}
                    onMouseOut={this.triggerEnd} onMouseOver={this.triggerStart}
                    onFocus={this.triggerStart} onBlur={this.triggerEnd}
                    />

            <Popover placement="top" target={`Popover-${this.state.popover_id}`} isOpen={this.state.open}>
              <PopoverHeader>{this.props.title}</PopoverHeader>
              <PopoverBody>{this.props.content}</PopoverBody>
            </Popover>
           </span>
  }
}

const HelpTooltip = (props) => {
  // generate a random ID for the tooltip that does not change on re-renders
  const [tooltipId] = useState(`tooltip${Math.floor(Math.random() * 1000000000)}`);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <a id={tooltipId} href="#" className="help-tooltip" onClick={(e) => e.preventDefault()}>
        <FaIcon name="info icon-2x help-icon fa-fw" />
      </a>

      <Tooltip
        isOpen={tooltipOpen}
        target={tooltipId}
        toggle={toggle}
      >
        {props.content}
      </Tooltip>
    </>
  )
}

HelpTooltip.propTypes = {
  content: PropTypes.node.isRequired
}

const FaIcon = (props) => {
  let {name, loading, ...child_props} = props;

  if(loading)
    return <div className="css-spinloader inline"></div>;

  const fw = loading === false ? 'fa-fw' : '';

  return <i className={`fa fa-${name} ${fw}`} {...child_props} ></i>;
}

FaIcon.propTypes = {
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool
}

class InfoPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: false};

    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    this.setState((prevState) => ({open: !prevState.open}));
  }

  mouseOver(e) {
    this.setState({open: true});
  }

  mouseOut(e) {
    this.setState({open: false});
  }

  render() {
    return <span className={`react-popover ${this.props.extraClass}`} onClick={this.onClick} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>
        {this.props.children}
        {this.state.open &&
          <div className="popover fade top in">
            <div className="arrow"></div>
            {this.props.title && <h3 className="popover-title">{this.props.title}</h3>}
            <div className="popover-content">{this.props.content}</div>
          </div>}
      </span>;
  }
}

InfoPopover.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
  extraClass: PropTypes.string,
}

const CheckSvg = () => (
  <svg version="1.1" className="svg-icon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="67.5px" height="67.5px" viewBox="0 0 67.5 67.5" style={{enableBackground: 'new 0 0 67.5 67.5'}} xmlSpace="preserve">
    <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t.st0{fill:none;stroke:#FFFFFF;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n" }} />
    <polyline className="st0" points="14.6,36.5 25.6,47.6 53.1,20.1 " />
  </svg>
)

const MarkSvg = () => (
  <svg version="1.1" className="svg-icon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="67.5px" height="67.5px" viewBox="0 0 67.5 67.5" style={{enableBackground: 'new 0 0 67.5 67.5'}} xmlSpace="preserve">
    <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t.st0{fill:none;stroke:#FFFFFF;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n" }} />
    <polyline className="st0" points="30.8,49.7 30.8,10.9 43.3,20 31,29 " />
    <circle className="st0" cx="30.8" cy={50} r={7} />
  </svg>
)

const ProgressStep = (props) => {
  if(props.index == props.step)
    return <li className='progress-current'>
        <div className="icon-wrap"><MarkSvg /></div>
        <span className="progress-text">{props.name}</span>
      </li>;
  else if(props.index < props.step)
    return <li className='progress-done'>
        <div className="icon-wrap"><CheckSvg /></div>
        <span className="progress-text">{props.name}</span>
      </li>;

  return <li className='progress-todo'>
      <div className="icon-wrap step-num">{props.index+1}</div>
      <span className="progress-text">{props.name}</span>
    </li>;
}

ProgressStep.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  name: PropTypes.node.isRequired
};

const Progress = (props) => {
  return <ol className="progress-track mb-3" role="progressbar" aria-valuemin="1" aria-valuemax={props.steps.length} aria-valuenow={props.current+1} aria-valuetext={`Schritt ${props.current+1} von ${props.steps.length}: ${props.steps[props.current]}`}>
    {props.steps.map((item, index) => (<ProgressStep key={index} name={item} index={index} step={props.current} />))}
  </ol>;
}

Progress.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  current: PropTypes.number.isRequired
}


const BigPrice = (props) => {
  let [decimal, fraction] = props.price.toString().split('.');

  return <div className={`price-big precision${props.precision}`}>
      {props.old_price && <span className="old-price">{format_fractional_price(props.old_price)}</span>}
      {' '}
      <span className="decimal">{decimal},</span>
      {' '}
      <span className="fraction">{fraction && fraction.toString().padEnd(props.precision, '0')}</span>
      {' '}
      <span className="period">{props.period ? `€/${props.period}` : '€'}</span>
    </div>
}

BigPrice.propTypes = {
  price: PropTypes.number.isRequired,
  precision: PropTypes.number.isRequired,
  period: PropTypes.string,
  old_price: PropTypes.number
};

BigPrice.defaultProps = {
  precision: 2
}

const Alert = (props) => (<div className={`alert alert-${props.alert_class}`}>
    {!!props.title && <strong>{props.title}</strong>}
    {' '}
    {props.text || props.children}
  </div>)

Alert.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node.isRequired,
  alert_class: PropTypes.oneOf(['danger', 'warning', 'success', 'info']).isRequired
};

Alert.defaultProps = {
  alert_class: 'warning'
}

const OverviewBoxLine = (props) => {
  return (
    <div className="overview-box-line mb-5">
      {props.children}
    </div>
  );
}

const OverviewBoxLineItem = (props) => {
  return (
    <div className="overview-box-item">
      <div className="text-muted">{props.label}</div>
      {props.children}
    </div>
  );
}

const DetailsSection = ({title, intro, children, className}) => {
  return (
    <>
      {!!title && <h5>{title}</h5>}

      {intro}

      <table className={`table table-borderless table-sm table-details-section ${className ? className : null}`}>
        <tbody>
          {children}
        </tbody>
      </table>
    </>
  );
}

DetailsSection.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  intro: PropTypes.node,
  className: PropTypes.string
}

const DetailsRow = (props) => {
  return (
    <tr>
      <td>{props.title}</td>
      <td>
        {props.text || props.children}
      </td>
    </tr>
  )
}

DetailsRow.propTypes = {
  title: PropTypes.node.isRequired,

  // one of children or text is required
  text: PropTypes.node,
  children: PropTypes.node
}

const SkeletonLoading = ({width = '100%', height = '1rem'}) => (
  <div className="skeleton-box" style={{width: width, height: height}}></div>
)

export { Alert, BackLink, BigPrice, BreadcrumbItem, Card, CardList, CardListItem, DetailsRow, DetailsSection, FaIcon, HelpQuestionMark, HelpTooltip, InfoPopover, OverviewBoxLine, OverviewBoxLineItem, PageHeader, Progress, ResourceBreadcrumbs, SkeletonLoading };

